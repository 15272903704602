import * as React from "react"
import {graphql } from "gatsby"


import Navbar from "../../components/navbar"
import Reformfooter from "../../components/footer"
// import Bookaclass from "../../components/bookaclass"
import Internalbanner from "../../components/internalbanner"
// import Sectiona from "../../homepage/sectiona"
// import Sectionb from "../../homepage/sectionb"
import Imagetrio from "../../components/imagetrio"
import Findus from "../../homepage/findus"
import Homeswiper from "../../homepage/homeswiper"
// import Swipage from "../../components/swiper"
import Sidebar from "../../components/sidebar"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Yogaback from "../../images/yoga3.jpg";
import YogaPic1 from "../../images/studio/advanced-1.jpg";
import YogaPic2 from "../../images/studio/advanced-2.jpg";
import YogaPic3 from "../../images/studio/advanced-3.jpg";
import YogaPic4 from "../../images/studio/advanced-4.jpg";

const Advanced_reformer = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Advanced Reformer`


  return (
    <Layout location={location} title={siteTitle}>
    <Seo title="Advanced Reformer Pilates" description="Advanced Reformer Pilates at Reform Pilates Cork"/>
    <Navbar />
    <Internalbanner title="Advanced Reformer" subtitle="A smarter and more effective workout" bannerpic={YogaPic1}/>
    <section className="internal-page-main gencont">
    <div className="container">
    <div className="columns is-marginless">
    <div className="column is-8">

    <h3>Advanced Reformer</h3><hr/>
    <p class="toningtag">
    Perfect for: <span className="tag">toning arms</span><span className="tag">legs</span><span className="tag">defining waist</span> <span className="tag">posture</span> <span className="tag">core strength</span>
    </p>
    <p class="blacksignature">A Quicker Pace</p>
<p>A class that is very much geared towards more experienced practitioners, Advanced Reformer seeks to provide more challenging and learning sessions to individuals.</p> 
<p>The class is a quicker pace than Improvers Reformer and suitable to clients with a strong Pilates base already and who wish to develop their skills further. </p>
    <div className="columns is-marginless is-centered">
    <div className="column is-8 is-paddingless">
    <img src={YogaPic1} alt="Advanced Yoga Classes Cork" className="midyogapic"/>
    </div>
    </div>

<p>Clients are encouraged to push themselves within their own limit and are given options at each stage to push themselves further or pull back a little, depending on their own ability. </p>

<p>Being injury-free is a definite advantage for this one.</p>


    </div>
    <div className="column is-3 is-offset-1">
    <Sidebar />
    </div>
    </div>
    </div>
    </section>
        <Imagetrio imagea={YogaPic2} imageb={YogaPic3} imagec={YogaPic4} />
    <Findus />
    <Homeswiper />
    <Reformfooter />
    </Layout>
    )
}

export default Advanced_reformer

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
`
